import React from "react"
import {graphql} from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sidebar from '../../components/sidebar' 

const FlayersPage = ({data}) => {
  return(
  <Layout>
    <SEO title="CORSAF Flayers" />
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <h1 class="text-2xl font-bold mb-8 md:mt-10 divider">Flayers Corsaf Design</h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 gap-8 md:col-span-3 md:my-5 mx-3"> {/** first element */}
        <div class="flayer-wraper flex">
          { data.allFile.edges.map(({ node }) =>
            <div class="w-full md:w-1/2 p-1">
              <Img fluid={node.childImageSharp.full} />
            </div>
          )}
        </div>
      </div>
      <div>
        <Sidebar />
      </div>
    </div>
  </Layout>
)
}
export const query = graphql`
query FlayersRequest {
  allFile (filter:{relativeDirectory: {eq: "img/telechargement/flayers"}}){
    edges {
      node {
        childImageSharp {
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default FlayersPage